import { createContext, PropsWithChildren, SetStateAction, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '@zastrpay/auth';
import { useErrorBoundary } from '@zastrpay/components';

import { skip as doSkip, submitData as doSubmitData, getList } from './api';
import { EMAIL_KYC_ENABLED } from './config';
import { isRequiredKycRequest, isVisibleKycRequest, KycRequest, ReferenceEntityType, sort, SubmitDataRequest } from './models';

type SubmittedState = {
    id: string;
    reactivateOn: Date;
};

type KycRequestState = {
    loaded: boolean;
    submitted?: SubmittedState;
    completed: boolean;

    pendingRequests: KycRequest[];
    conditionalRequests: KycRequest[];

    skippedRequests: string[];
};

export type KycRequestContext = {
    pendingRequests: KycRequest[];
    conditionalRequests: KycRequest[];

    loaded: boolean;
    completed: boolean;
    skip: (kycRequest: KycRequest) => void;
    seen: (kycRequest: KycRequest) => Promise<void>;
    submit: (kycRequest: KycRequest) => void;
    complete: (kycRequest: KycRequest) => void;
    submitData: (kycRequest: KycRequest, data: SubmitDataRequest, partial?: boolean) => Promise<void>;
    loadRequests: () => Promise<void>;
    getById: (id: string) => KycRequest | undefined;
};

export type KycRequestProviderProps = {
    prioritizeRequiredKyc?: boolean;
    /**
     * List of reference types that indicate conditional kyc requests
     */
    conditionalReferenceTypes?: ReferenceEntityType[];
};

const Context = createContext<KycRequestContext | null>(null);

const REACTIVATE_AFTER = 10_000;

export const KycRequestProvider: React.FC<PropsWithChildren<KycRequestProviderProps>> = ({
    conditionalReferenceTypes = [],
    prioritizeRequiredKyc = false,
    children,
}) => {
    const handle = useRef<ReturnType<typeof setTimeout>>();

    const { trackError } = useErrorBoundary();
    const { state, customerId, refreshCustomer } = useAuth();
    const navigate = useNavigate();

    const [{ loaded, pendingRequests, conditionalRequests, skippedRequests, completed }, setState] = useState<KycRequestState>({
        loaded: false,
        completed: true,
        pendingRequests: [],
        conditionalRequests: [],
        skippedRequests: [],
    });

    const submit = (kycRequest: KycRequest) => {
        if (!kycRequest) {
            throw new Error('KycRequest is required');
        }

        if (handle.current === undefined) {
            const now = Date.now();

            const updatedPendingRequests = pendingRequests.filter((request) => request.id !== kycRequest.id);
            const updatedConditionalRequests = conditionalRequests.filter((request) => request.id !== kycRequest.id);

            setState({
                loaded: true,
                pendingRequests: updatedPendingRequests,
                conditionalRequests: updatedConditionalRequests,
                skippedRequests,
                completed: false,
                submitted: {
                    id: kycRequest.id,
                    reactivateOn: new Date(now + REACTIVATE_AFTER),
                },
            });

            handle.current = setTimeout(() => loadRequests(), REACTIVATE_AFTER);
        }
    };

    const complete = () => {
        refreshCustomer().then(() => {
            setState((state) => ({ ...state, completed: state.pendingRequests.length === 0 }));
        });
    };

    const submitData = async (kycRequest: KycRequest, data: SubmitDataRequest, skipPendingExternalVerification?: boolean) => {
        if (!kycRequest) {
            throw new Error('KycRequest is required');
        }

        const updated = await doSubmitData(kycRequest.id, data);

        // if the request is still pending, we should wait for the external verification
        // the consumer of this api should handle the push notifications
        if (!skipPendingExternalVerification || updated.state !== 'PendingExternalVerification') {
            const updatedPendingRequests = pendingRequests.filter((request) => request.id !== kycRequest.id);
            const updatedConditionalRequests = conditionalRequests.filter((request) => request.id !== kycRequest.id);

            setState({
                loaded: true,
                completed: false,
                pendingRequests: updatedPendingRequests,
                conditionalRequests: updatedConditionalRequests,
                skippedRequests,
            });
        }
    };

    const skip = (kycRequest: KycRequest) => {
        if (!kycRequest) {
            throw new Error('KycRequest is required');
        }

        if (kycRequest.state === 'Pending' || kycRequest.type === 'EmailVerification') {
            const updatedSkippedRequests = [...skippedRequests, kycRequest.id];
            const updatedPendingRequest = pendingRequests.filter((request) => !updatedSkippedRequests.includes(request.id));

            setState({
                loaded: true,
                pendingRequests: updatedPendingRequest,
                conditionalRequests,
                completed: updatedPendingRequest.length === 0,
                skippedRequests: updatedSkippedRequests,
            });

            if (!completed) {
                navigate('/kyc-request');
            }
        }
    };

    const seen = async (kycRequest: KycRequest) => {
        if (!kycRequest) {
            throw new Error('KycRequest is required');
        }

        if (kycRequest.dueAfter) {
            const skipped = await doSkip(kycRequest.id);

            const updatedPendingRequests = pendingRequests.map((request) => (request.id === skipped.id ? skipped : request));
            const updatedConditionalRequests = conditionalRequests.map((request) => (request.id === skipped.id ? skipped : request));

            setState({
                loaded: true,
                completed: false,
                pendingRequests: updatedPendingRequests,
                conditionalRequests: updatedConditionalRequests,
                skippedRequests,
            });
        }
    };

    const filterKycRequests = useCallback(
        (allRequests: KycRequest[], skippedRequests: string[]) => {
            // assuming that conditional requests can't be skipped
            const conditionalRequests = allRequests.filter(
                ({ referenceEntities }) =>
                    referenceEntities?.length && referenceEntities.every((entity) => conditionalReferenceTypes.includes(entity.type)),
            );

            // filter out skipped and conditional requests
            const visibleRequests = allRequests.filter(
                (request) =>
                    isVisibleKycRequest(request) &&
                    !skippedRequests.includes(request.id) &&
                    !conditionalRequests.some((conditional) => conditional.id === request.id),
            );

            const requiredRequests: KycRequest[] = [];
            const skippableRequests: KycRequest[] = [];

            for (const request of visibleRequests) {
                if (!EMAIL_KYC_ENABLED && request.type === 'EmailVerification') {
                    continue;
                }

                if (isRequiredKycRequest(request)) {
                    requiredRequests.push(request);
                } else {
                    skippableRequests.push(request);
                }
            }

            requiredRequests.sort(sort);
            skippableRequests.sort(sort);
            conditionalRequests.sort(sort);

            return {
                pendingRequests:
                    prioritizeRequiredKyc && requiredRequests.length > 0 ? requiredRequests : [...requiredRequests, ...skippableRequests],
                conditionalRequests,
            };
        },
        [conditionalReferenceTypes, prioritizeRequiredKyc],
    );

    const loadRequests = useCallback(async () => {
        const updateState = (updated: SetStateAction<KycRequestState>) => {
            setState(updated);

            if (handle.current !== undefined) {
                clearTimeout(handle.current);
                handle.current = undefined;
            }
        };

        try {
            if (state === 'authenticated') {
                const requests = await getList(customerId);

                updateState(({ skippedRequests, completed }) => {
                    const { pendingRequests, conditionalRequests } = filterKycRequests(requests, skippedRequests);

                    return {
                        loaded: true,
                        failed: false,
                        completed: completed && pendingRequests.length === 0,
                        pendingRequests,
                        conditionalRequests,
                        skippedRequests,
                    };
                });
            }
        } catch (error) {
            trackError(error);

            updateState({
                loaded: true,
                completed: true,
                pendingRequests: [],
                conditionalRequests: [],
                skippedRequests: [],
            });
        }
    }, [state, customerId, trackError, filterKycRequests]);

    const getById = useCallback(
        (id: string) => {
            const pending = pendingRequests.find((request) => request.id === id);
            if (pending) {
                return pending;
            }

            return conditionalRequests.find((request) => request.id === id);
        },
        [pendingRequests, conditionalRequests],
    );

    useEffect(() => {
        loadRequests();
    }, [loadRequests]);

    return (
        <Context.Provider
            value={{
                loaded,
                completed,
                pendingRequests,
                conditionalRequests,
                submit,
                complete,
                loadRequests,
                seen,
                skip,
                submitData,
                getById,
            }}
        >
            {children}
        </Context.Provider>
    );
};

export const useKycRequest = (): KycRequestContext => {
    const context = useContext(Context);

    if (!context) {
        throw new Error('useKycRequest must be used within a KycRequestProvider');
    }

    return context;
};
